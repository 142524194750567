/**
 * AAA IDP store
 * @flow
 */
import React from 'react';
import type { Application, AppState } from '../../types/Types';
import './ClubStore.css';
import { deliveryPickUpStore } from '../../actions';
import { connect } from 'react-redux';

type Props = {
  ...AppState,
  application: Application,
  clubStore: Object,
  deliveryPickUpStore: number,
  key: number,
  setDeliveryPickUpStore: (value: number) => *
};

export class ClubStore extends React.Component<Props, State> {

  static defaultProps = {
    application: {
      club: {
        id: '',
      },
    },
    deliveryPickUpStore: 0
  };

  /**
   * onFocus
   */
  onFocus = (focus: number) => {
    const { setDeliveryPickUpStore } = this.props;

    setDeliveryPickUpStore(focus);

    this.setState({
      focus
    });
  };

  /**
   * onBlur
   */
  onBlur = () => {
    this.setState({
      focus: ''
    });
  };

  /**
   * Select the club store
   * @param value
   */
  select = (value: number) => {
    const { setDeliveryPickUpStore } = this.props;

    setDeliveryPickUpStore(value);
  };

  render() {
    const { clubStore, deliveryPickUpStore } = this.props;
    const checked = clubStore.id === deliveryPickUpStore.value;
    const highlight = checked ? ' highlight' : '';

    return (
    <div
      className="radiobutton-container ClubStore"
      key={clubStore.id}
      tabIndex="0"
      onBlur={() => this.onBlur}
      onFocus={() => this.onFocus(clubStore.id)}
    >
      <input
        id={`clubStore-${clubStore.id}`}
        className="radio-button-hidden"
        type="radio"
        checked={checked}
        value={clubStore.id}
        onChange={() => this.select(clubStore.id)}
      />
      <label htmlFor={`clubStore-${clubStore.id}`}>
        <div className="radio-display">
          <div className={`inner${highlight}`} />
        </div>

        {clubStore.name}, {clubStore.address_1}, {clubStore.address_suburb}, {clubStore.address_state}
      </label>
    </div>
  )
  }
}

const mapStateToProps = ({ application, deliveryPickUpStore }) => {
  return { application, deliveryPickUpStore };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeliveryPickUpStore: (value: string) => {
      dispatch(deliveryPickUpStore(value));
    }
  };
};

const VisibleClubStore = connect(mapStateToProps, mapDispatchToProps)(ClubStore);

export default VisibleClubStore;
