/**
 * AAA IDP delivery pick up store reducer
 * @flow
 */
import {DELETE, DELIVERY_PICK_UP_STORE} from '../../actions/types';
import type {ActionType} from '../../types/Types';

type State = {
  error: boolean,
  required: boolean,
  valid: boolean | null,
  value: number,
};

export const initialState = {
  error: false,
  required: true,
  valid: null,
  value: 0,
};

export default function(state: State = initialState, action: ActionType) {
  switch (action.type) {
    case DELETE :
      return initialState;

    case DELIVERY_PICK_UP_STORE : {
      const {value} = action;
      const valid = (Number.isInteger(value) && value > 0) ? true : false;

      return {
        ...state,
        error: !valid,
        value,
        valid,
      };
    }

    default:
      return state;
  }
}
