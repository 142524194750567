/**
 * AAA IDP Form - Data
 * @flow
 */
import { initContacts } from '../reducers/contacts/contactReducer';
import { initImages } from '../reducers/images/imageReducer';
import moment from 'moment';
const { hostname, protocol } = window.location;
export const MASTER_DOMAIN = 'www.internationaldrivingpermitsonline.com.au';
export const ADDRESS_FINDER = {
  key: 'Q7UCB6TL9GYFEJDMR3A4',
  url: 'https://api.addressfinder.io/api/au/address'
};
export const IS_LOCAL = new RegExp(/local/);
export const IS_DEV = new RegExp(/dev/);
export const IS_STAGE = new RegExp(/stage/);
export const IS_PREPROD = new RegExp(/prod/);
export function isProd(hostname: string) {
  switch (true) {
    case IS_LOCAL.test(hostname):
    case IS_DEV.test(hostname):
    case IS_STAGE.test(hostname):
      return false;

    default:
      return true;
  }
}
export const IS_PROD = isProd(hostname);
export const API_VERSION = 'v1';
// TODO: refactor to have one API path
export const API_PATH = `/api/${API_VERSION}/orders`;
export const STORES_API_PATH = `/api/${API_VERSION}`;
export const CHARS = 'abcdefghijklmnopqrstuvxyz0123456789';

// TODO: Update these URLS
/**
 * Get the API URL
 * @param {string} location The hostname of the FE
 * @param {boolean} order Whether ror not we need the order API or content
 * @param {boolean} stores Whether we are retrieving the stores
 * @returns
 */
export function apiUrl(location: string, order: boolean = true, stores: boolean = false): string {
  switch (true) {
    case IS_LOCAL.test(location) && order:
    case IS_DEV.test(location) && order:
      return `https://dev.idpbe.au1.ironstar.io${API_PATH}`;

    case IS_STAGE.test(location) && order:
      return `https://stage.idpbe.au1.ironstar.io${API_PATH}`;

    case order:
      return `https://portal.internationaldrivingpermitsonline.com.au${API_PATH}`;

    case IS_LOCAL.test(location) && stores:
    case IS_DEV.test(location) && stores:
      return `https://dev.idpbe.au1.ironstar.io${STORES_API_PATH}`;

    case IS_STAGE.test(location) && stores:
      return `https://stage.idpbe.au1.ironstar.io${STORES_API_PATH}`;

    case stores:
      return `https://portal.internationaldrivingpermitsonline.com.au${STORES_API_PATH}`;

    case IS_LOCAL.test(location) && !order:
    case IS_DEV.test(location) && !order:
      return 'https://dev.idpbe.au1.ironstar.io';

    case IS_STAGE.test(location) && !order:
      return 'https://stage.idpbe.au1.ironstar.io';

    case !order:
      return 'https://portal.internationaldrivingpermitsonline.com.au';

    default:
      return `https://portal.internationaldrivingpermitsonline.com.au${API_PATH}`;
  }
}

export const TERMS_URL = `${apiUrl(hostname, false)}/idp-terms-and-conditions`;
export const API = apiUrl(hostname);
export const STORES_API = apiUrl(hostname, false, true);

export const PHONE_COUNTRY_CODE = [
  { title: 'None', value: '' },
  { title: 'Afghanistan, 93', value: '93' },
  { title: 'Aland Islands, +358-18', value: '+358-18' },
  { title: 'Albania, 355', value: '355' },
  { title: 'Algeria, 213', value: '213' },
  { title: 'American Samoa, +1-684', value: '+1-684' },
  { title: 'Andorra, 376', value: '376' },
  { title: 'Angola, 244', value: '244' },
  { title: 'Anguilla, +1-264', value: '+1-264' },
  { title: 'Antarctica, 672', value: '672' },
  { title: 'Antigua And Barbuda, +1-268', value: '+1-268' },
  { title: 'Argentina, 54', value: '54' },
  { title: 'Armenia, 374', value: '374' },
  { title: 'Aruba, 297', value: '297' },
  { title: 'Australia, 61', value: '61' },
  { title: 'Austria, 43', value: '43' },
  { title: 'Azerbaijan, 994', value: '994' },
  { title: 'Bahrain, 973', value: '973' },
  { title: 'Bangladesh, 880', value: '880' },
  { title: 'Barbados, +1-246', value: '+1-246' },
  { title: 'Belarus, 375', value: '375' },
  { title: 'Belgium, 32', value: '32' },
  { title: 'Belize, 501', value: '501' },
  { title: 'Benin, 229', value: '229' },
  { title: 'Bermuda, +1-441', value: '+1-441' },
  { title: 'Bhutan, 975', value: '975' },
  { title: 'Bolivia, 591', value: '591' },
  { title: 'Bonaire, Sint Eustatius and Saba, 599', value: '599' },
  { title: 'Bosnia and Herzegovina, 387', value: '387' },
  { title: 'Botswana, 267', value: '267' },
  { title: 'Bouvet Island, 0055', value: '0055' },
  { title: 'Brazil, 55', value: '55' },
  { title: 'British Indian Ocean Territory, 246', value: '246' },
  { title: 'Brunei, 673', value: '673' },
  { title: 'Bulgaria, 359', value: '359' },
  { title: 'Burkina Faso, 226', value: '226' },
  { title: 'Burundi, 257', value: '257' },
  { title: 'Cambodia, 855', value: '855' },
  { title: 'Cameroon, 237', value: '237' },
  { title: 'Canada, 1', value: '1' },
  { title: 'Cape Verde, 238', value: '238' },
  { title: 'Cayman Islands, +1-345', value: '+1-345' },
  { title: 'Central African Republic, 236', value: '236' },
  { title: 'Chad, 235', value: '235' },
  { title: 'Chile, 56', value: '56' },
  { title: 'China, 86', value: '86' },
  { title: 'Colombia, 57', value: '57' },
  { title: 'Comoros, 269', value: '269' },
  { title: 'Congo, 242', value: '242' },
  { title: 'Cook Islands, 682', value: '682' },
  { title: 'Costa Rica, 506', value: '506' },
  { title: 'Cote D\'Ivoire (Ivory Coast), 225', value: '225' },
  { title: 'Croatia, 385', value: '385' },
  { title: 'Cuba, 53', value: '53' },
  { title: 'Curaçao, 599', value: '599' },
  { title: 'Cyprus, 357', value: '357' },
  { title: 'Czech Republic, 420', value: '420' },
  { title: 'Democratic Republic of the Congo, 243', value: '243' },
  { title: 'Denmark, 45', value: '45' },
  { title: 'Djibouti, 253', value: '253' },
  { title: 'Dominica, +1-767', value: '+1-767' },
  {
    title: 'Dominican Republic, +1-809',
    value: '+1-809'
  },
  { title: 'East Timor, 670', value: '670' },
  { title: 'Ecuador, 593', value: '593' },
  { title: 'Egypt, 20', value: '20' },
  { title: 'El Salvador, 503', value: '503' },
  { title: 'Equatorial Guinea, 240', value: '240' },
  { title: 'Eritrea, 291', value: '291' },
  { title: 'Estonia, 372', value: '372' },
  { title: 'Ethiopia, 251', value: '251' },
  { title: 'Falkland Islands, 500', value: '500' },
  { title: 'Faroe Islands, 298', value: '298' },
  { title: 'Fiji Islands, 679', value: '679' },
  { title: 'Finland, 358', value: '358' },
  { title: 'France, 33', value: '33' },
  { title: 'French Guiana, 594', value: '594' },
  { title: 'French Polynesia, 689', value: '689' },
  { title: 'French Southern Territories, 262', value: '262' },
  { title: 'Gabon, 241', value: '241' },
  { title: 'Gambia The, 220', value: '220' },
  { title: 'Georgia, 995', value: '995' },
  { title: 'Germany, 49', value: '49' },
  { title: 'Ghana, 233', value: '233' },
  { title: 'Gibraltar, 350', value: '350' },
  { title: 'Greece, 30', value: '30' },
  { title: 'Greenland, 299', value: '299' },
  { title: 'Grenada, +1-473', value: '+1-473' },
  { title: 'Guadeloupe, 590', value: '590' },
  { title: 'Guam, +1-671', value: '+1-671' },
  { title: 'Guatemala, 502', value: '502' },
  { title: 'Guernsey and Alderney, +44-1481', value: '+44-1481' },
  { title: 'Guinea, 224', value: '224' },
  { title: 'Guinea-Bissau, 245', value: '245' },
  { title: 'Guyana, 592', value: '592' },
  { title: 'Haiti, 509', value: '509' },
  { title: 'Heard Island and McDonald Islands, 672', value: '672' },
  { title: 'Honduras, 504', value: '504' },
  { title: 'Hong Kong S.A.R., 852', value: '852' },
  { title: 'Hungary, 36', value: '36' },
  { title: 'Iceland, 354', value: '354' },
  { title: 'India, 91', value: '91' },
  { title: 'Indonesia, 62', value: '62' },
  { title: 'Iran, 98', value: '98' },
  { title: 'Iraq, 964', value: '964' },
  { title: 'Ireland, 353', value: '353' },
  { title: 'Israel, 972', value: '972' },
  { title: 'Italy, 39', value: '39' },
  { title: 'Jamaica, +1-876', value: '+1-876' },
  { title: 'Japan, 81', value: '81' },
  { title: 'Jersey, +44-1534', value: '+44-1534' },
  { title: 'Jordan, 962', value: '962' },
  { title: 'Kazakhstan, 7', value: '7' },
  { title: 'Kenya, 254', value: '254' },
  { title: 'Kiribati, 686', value: '686' },
  { title: 'Kosovo, 383', value: '383' },
  { title: 'Kuwait, 965', value: '965' },
  { title: 'Kyrgyzstan, 996', value: '996' },
  { title: 'Laos, 856', value: '856' },
  { title: 'Latvia, 371', value: '371' },
  { title: 'Lebanon, 961', value: '961' },
  { title: 'Lesotho, 266', value: '266' },
  { title: 'Liberia, 231', value: '231' },
  { title: 'Libya, 218', value: '218' },
  { title: 'Liechtenstein, 423', value: '423' },
  { title: 'Lithuania, 370', value: '370' },
  { title: 'Luxembourg, 352', value: '352' },
  { title: 'Macau S.A.R., 853', value: '853' },
  { title: 'Macedonia, 389', value: '389' },
  { title: 'Madagascar, 261', value: '261' },
  { title: 'Malawi, 265', value: '265' },
  { title: 'Malaysia, 60', value: '60' },
  { title: 'Maldives, 960', value: '960' },
  { title: 'Mali, 223', value: '223' },
  { title: 'Malta, 356', value: '356' },
  { title: 'Man (Isle of), +44-1624', value: '+44-1624' },
  { title: 'Marshall Islands, 692', value: '692' },
  { title: 'Martinique, 596', value: '596' },
  { title: 'Mauritania, 222', value: '222' },
  { title: 'Mauritius, 230', value: '230' },
  { title: 'Mayotte, 262', value: '262' },
  { title: 'Mexico, 52', value: '52' },
  { title: 'Micronesia, 691', value: '691' },
  { title: 'Moldova, 373', value: '373' },
  { title: 'Monaco, 377', value: '377' },
  { title: 'Mongolia, 976', value: '976' },
  { title: 'Montenegro, 382', value: '382' },
  { title: 'Montserrat, +1-664', value: '+1-664' },
  { title: 'Morocco, 212', value: '212' },
  { title: 'Mozambique, 258', value: '258' },
  { title: 'Myanmar, 95', value: '95' },
  { title: 'Namibia, 264', value: '264' },
  { title: 'Nauru, 674', value: '674' },
  { title: 'Nepal, 977', value: '977' },
  { title: 'Netherlands, 31', value: '31' },
  { title: 'New Caledonia, 687', value: '687' },
  { title: 'New Zealand, 64', value: '64' },
  { title: 'Nicaragua, 505', value: '505' },
  { title: 'Niger, 227', value: '227' },
  { title: 'Nigeria, 234', value: '234' },
  { title: 'Niue, 683', value: '683' },
  { title: 'Norfolk Island, 672', value: '672' },
  { title: 'North Korea, 850', value: '850' },
  { title: 'Northern Mariana Islands, +1-670', value: '+1-670' },
  { title: 'Norway, 47', value: '47' },
  { title: 'Oman, 968', value: '968' },
  { title: 'Pakistan, 92', value: '92' },
  { title: 'Palau, 680', value: '680' },
  { title: 'Palestinian Territory Occupied, 970', value: '970' },
  { title: 'Panama, 507', value: '507' },
  { title: 'Papua new Guinea, 675', value: '675' },
  { title: 'Paraguay, 595', value: '595' },
  { title: 'Peru, 51', value: '51' },
  { title: 'Philippines, 63', value: '63' },
  { title: 'Pitcairn Island, 870', value: '870' },
  { title: 'Poland, 48', value: '48' },
  { title: 'Portugal, 351', value: '351' },
  { title: 'Puerto Rico, +1-787 and 1-939', value: '+1-787 and 1-939' },
  { title: 'Qatar, 974', value: '974' },
  { title: 'Reunion, 262', value: '262' },
  { title: 'Romania, 40', value: '40' },
  { title: 'Russia, 7', value: '7' },
  { title: 'Rwanda, 250', value: '250' },
  { title: 'Saint Helena, 290', value: '290' },
  { title: 'Saint Kitts And Nevis, +1-869', value: '+1-869' },
  { title: 'Saint Lucia, +1-758', value: '+1-758' },
  { title: 'Saint Pierre and Miquelon, 508', value: '508' },
  { title: 'Saint Vincent And The Grenadines, +1-784', value: '+1-784' },
  { title: 'Saint-Barthelemy, 590', value: '590' },
  { title: 'Saint-Martin (French part), 590', value: '590' },
  { title: 'Samoa, 685', value: '685' },
  { title: 'San Marino, 378', value: '378' },
  { title: 'Sao Tome and Principe, 239', value: '239' },
  { title: 'Saudi Arabia, 966', value: '966' },
  { title: 'Senegal, 221', value: '221' },
  { title: 'Serbia, 381', value: '381' },
  { title: 'Seychelles, 248', value: '248' },
  { title: 'Sierra Leone, 232', value: '232' },
  { title: 'Singapore, 65', value: '65' },
  { title: 'Sint Maarten (Dutch part), 1721', value: '1721' },
  { title: 'Slovakia, 421', value: '421' },
  { title: 'Slovenia, 386', value: '386' },
  { title: 'Solomon Islands, 677', value: '677' },
  { title: 'Somalia, 252', value: '252' },
  { title: 'South Africa, 27', value: '27' },
  { title: 'South Georgia, 500', value: '500' },
  { title: 'South Korea, 82', value: '82' },
  { title: 'South Sudan, 211', value: '211' },
  { title: 'Spain, 34', value: '34' },
  { title: 'Sri Lanka, 94', value: '94' },
  { title: 'Sudan, 249', value: '249' },
  { title: 'Suriname, 597', value: '597' },
  { title: 'Svalbard And Jan Mayen Islands, 47', value: '47' },
  { title: 'Swaziland, 268', value: '268' },
  { title: 'Sweden, 46', value: '46' },
  { title: 'Switzerland, 41', value: '41' },
  { title: 'Syria, 963', value: '963' },
  { title: 'Taiwan, 886', value: '886' },
  { title: 'Tajikistan, 992', value: '992' },
  { title: 'Tanzania, 255', value: '255' },
  { title: 'Thailand, 66', value: '66' },
  { title: 'The Bahamas, +1-242', value: '+1-242' },
  { title: 'Togo, 228', value: '228' },
  { title: 'Tokelau, 690', value: '690' },
  { title: 'Tonga, 676', value: '676' },
  { title: 'Trinidad And Tobago, +1-868', value: '+1-868' },
  { title: 'Tunisia, 216', value: '216' },
  { title: 'Turkey, 90', value: '90' },
  { title: 'Turkmenistan, 993', value: '993' },
  { title: 'Turks And Caicos Islands, +1-649', value: '+1-649' },
  { title: 'Tuvalu, 688', value: '688' },
  { title: 'Uganda, 256', value: '256' },
  { title: 'Ukraine, 380', value: '380' },
  { title: 'United Arab Emirates, 971', value: '971' },
  { title: 'United Kingdom, 44', value: '44' },
  { title: 'United States Minor Outlying Islands, 1', value: '1' },
  { title: 'United States, 1', value: '1' },
  { title: 'Uruguay, 598', value: '598' },
  { title: 'Uzbekistan, 998', value: '998' },
  { title: 'Vanuatu, 678', value: '678' },
  { title: 'Vatican City State (Holy See), 379', value: '379' },
  { title: 'Venezuela, 58', value: '58' },
  { title: 'Vietnam, 84', value: '84' },
  { title: 'Virgin Islands (British), +1-284', value: '+1-284' },
  { title: 'Virgin Islands (US), +1-340', value: '+1-340' },
  { title: 'Wallis And Futuna Islands, 681', value: '681' },
  { title: 'Western Sahara, 212', value: '212' },
  { title: 'Yemen, 967', value: '967' },
  { title: 'Zambia, 260', value: '260' },
  { title: 'Zimbabwe, 263', value: '263' }
];

export const AUTO_CLUB = [
  { title: 'Not a club member', value: 'NA' },
  { title: 'AANT', value: 'AANT' },
  { title: 'NRMA', value: 'NRMA' },
  { title: 'RAA', value: 'RAA' },
  { title: 'RAC', value: 'RAC' },
  { title: 'RACQ', value: 'RACQ' },
  { title: 'RACT', value: 'RACT' },
  { title: 'RACV', value: 'RACV' }
];

export const BINARY = [
  { default: true, title: 'No', value: 'No' },
  { default: false, title: 'Yes', value: 'Yes' }
];

export const BUGSNAG = {
  token: '59060a252d32945e709bb2cccd68a7a7'
};

export const CC_RE = new RegExp(/^[0-9]{4}\s{0,1}[0-9]{4}\s{0,1}[0-9]{4}\s{0,1}[0-9]{3,4}\s{0,1}$/);
export const EXPIRY_RE = new RegExp(/^[0-9]{2}$/);

export const DOMAINS = {
  NSW: {
    local: 'nrma.idp.local',
    dev: 'nrma.dev.idpfe.au1.ironstar.io',
    stage: 'nrma.stage.idpfe.au1.ironstar.io',
    preProd: 'nrma.prod.idpfe.au1.ironstar.io',
    prod: 'www.mynrmaidp.com.au'
  },
  // ACT is after NSW to return NSW on NRMA's site
  ACT: {
    local: 'nrma.idp.local',
    dev: 'nrma.dev.idpfe.au1.ironstar.io',
    stage: 'nrma.stage.idpfe.au1.ironstar.io',
    preProd: 'nrma.prod.idpfe.au1.ironstar.io',
    prod: 'www.mynrmaidp.com.au'
  },
  NT: {
    local: 'aant.idp.local',
    dev: 'aant.dev.idpfe.au1.ironstar.io',
    stage: 'aant.stage.idpfe.au1.ironstar.io',
    preProd: 'aant.prod.idpfe.au1.ironstar.io',
    prod: 'www.idp.aant.com.au'
  },
  QLD: {
    local: 'racq.idp.local',
    dev: 'racq.dev.idpfe.au1.ironstar.io',
    stage: 'racq.stage.idpfe.au1.ironstar.io',
    preProd: 'racq.prod.idpfe.au1.ironstar.io',
    prod: 'www.idp.racq.com.au'
  },
  SA: {
    local: 'raa.idp.local',
    dev: 'raa.dev.idpfe.au1.ironstar.io',
    stage: 'raa.stage.idpfe.au1.ironstar.io',
    preProd: 'raa.prod.idpfe.au1.ironstar.io',
    prod: 'www.idp.raa.com.au'
  },
  TAS: {
    local: 'ract.idp.local',
    dev: 'ract.dev.idpfe.au1.ironstar.io',
    stage: 'ract.stage.idpfe.au1.ironstar.io',
    preProd: 'ract.prod.idpfe.au1.ironstar.io',
    prod: 'www.ractidp.com.au'
  },
  VIC: {
    local: 'racv.idp.local',
    dev: 'racv.dev.idpfe.au1.ironstar.io',
    stage: 'racv.stage.idpfe.au1.ironstar.io',
    preProd: 'racv.prod.idpfe.au1.ironstar.io',
    prod: 'www.internationaldrivingpermit.com.au'
  },
  WA: {
    local: 'rac.idp.local',
    dev: 'rac.dev.idpfe.au1.ironstar.io',
    stage: 'rac.stage.idpfe.au1.ironstar.io',
    preProd: 'rac.prod.idpfe.au1.ironstar.io',
    prod: 'overseas-license.rac.com.au'
  }
};

export const CLUBS = {
  ACT: 'NRMA',
  NSW: 'NRMA',
  NT: 'AANT',
  QLD: 'RACQ',
  SA: 'RAA',
  TAS: 'RACT',
  VIC: 'RACV',
  WA: 'RAC'
};

export const CLUB_CONTACTS = {
  NRMA: {
    phone: '1300 053 052 (+611300053052)',
    email: 'permits@mynrma.com.au'
  },
  AANT: {
    phone: '(08) 8925 5901 (+61889255901)',
    email: 'info@aant.com.au'
  },
  RACQ: {
    phone: null,
    email: 'idp@racq.com.au'
  },
  RAA: {
    phone: '(08) 8202 4600 (+61882024600)',
    email: 'no-reply@idp.raa.com.au'
  },
  RACT: {
    phone: '13 27 22 (+61132722)',
    email: null
  },
  RACV: {
    phone: '131 329 (+61131329)',
    email: 'idp@racv.com.au'
  },
  RAC: {
    phone: '08 9436 4840 (+61894364840)',
    email: 'TouringOnline@rac.com.au'
  },
  AAA: {
    phone: '02 6247 7311 (+61262477311)',
    email: 'international@aaa.asn.au'
  }
};

export function clubDomain(host: string) {
  for (const state in DOMAINS) {
    for (const env in DOMAINS[state]) {
      if (host === DOMAINS[state][env]) {
        return CLUBS[state];
      }
    }
  }
  return 'AAA';
}

export function getEnv(host: string) {
  for (const state in DOMAINS) {
    for (const env in DOMAINS[state]) {
      if (host === DOMAINS[state][env]) {
        return env;
      }
    }
  }
  return 'prod';
}

export function appDomain(host: string, state: string) {
  if (!state || !stateValid(state) || !host) {
    return '';
  }

  switch (true) {
    case IS_LOCAL.test(host):
      return DOMAINS[state].local;

    case IS_DEV.test(host):
      return DOMAINS[state].dev;

    case IS_STAGE.test(host):
      return DOMAINS[state].stage;

    case IS_PREPROD.test(host):
      return DOMAINS[state].preProd;

    default:
      return DOMAINS[state].prod;
  }
}

// host from window.location.host
export function stateForDomain(host: string) {
  // Don't return a state for the default domain
  if (host === MASTER_DOMAIN) {
    return '';
  }

  for (const state in DOMAINS) {
    for (const env in DOMAINS[state]) {
      const url = DOMAINS[state][env];
      if (host.match(url)) {
        return state;
      }
    }
  }

  return '';
}

// payment gateways per state
export const GATEWAYS = {
  ACT: {
    name: 'nab-securepay',
    method: 'form'
  },
  NSW: {
    name: 'nab-securepay',
    method: 'form'
  },
  NT: {
    name: 'westpac',
    method: 'redirect'
  },
  QLD: {
    name: 'fatzebra',
    method: 'iframe'
  },
  SA: {
    name: 'ipsi',
    method: 'iframe'
  },
  TAS: {
    name: 'windcave',
    method: 'form'
  },
  VIC: {
    name: 'nab-securepay',
    method: 'form'
  },
  WA: {
    name: 'fatzebra',
    method: 'iframe'
  }
};

// host from window.location.host
export function gatewayForDomain(
  host: string,
  id?: string | null,
  submission_token?: string | null,
  amount?: string | null,
  postageDescription?: string | null
): { name: string, method: string, redirectUrl: string } {
  for (const state in DOMAINS) {
    for (const env in DOMAINS[state]) {
      const url = DOMAINS[state][env];

      if (host.match(url)) {
        const { name, method } = GATEWAYS[state];
        const http = IS_LOCAL.test(host) ? protocol : 'https:';
        const search = new URLSearchParams();
        search.set('appId', id ? id : '');
        search.set('submission_token', submission_token ? submission_token : '');
        search.set('postage', postageDescription ? postageDescription : 'Express Post within Australia');
        search.set('chargeAmount', amount ? amount : '');

        return {
          name,
          method,
          redirectUrl: `${http}//${host}/completed?${search.toString()}`
        };
      }
    }
  }

  return {
    name: '',
    method: '',
    redirectUrl: ''
  };
}

export const GTM = {
  AAA: {
    prod: ['GTM-TRCZ95W', 'GTM-MZQ5NSV'],
    stage: []
  },
  AANT: {
    prod: ['GTM-5T4466C', 'GTM-T6J5KKF'],
    stage: []
  },
  NRMA: {
    prod: ['GTM-NT4L8PD', 'GTM-N2H7KCN'],
    stage: []
  },
  RAA: {
    prod: ['GTM-N735VRS', 'GTM-MVTK85X'],
    stage: ['GTM-T8V28W2']
  },
  RAC: {
    prod: ['GTM-M6FBSN', 'GTM-MR7B6ST'],
    stage: []
  },
  RACQ: {
    prod: ['GTM-WLMR5LG', 'GTM-N247Z3T'],
    stage: []
  },
  RACT: {
    prod: ['GTM-KMTKMK4', 'GTM-KD7JMHX'],
    stage: []
  },
  RACV: {
    prod: ['GTM-NRCD4XC'],
    stage: []
  }
};

export const gtmForClubEnv = (host: string): Array<string> => {
  const club = clubDomain(host);
  const env = getEnv(host);
  return GTM[club][env] || [];
}

export const GA = {
  AAA: null,
  AANT: null,
  NRMA: 'UA-15177944-1',
  RAA: 'UA-114852577-1',
  RAC: null,
  RACQ: null,
  RACV: null
};

export const STATES = [
  { title: 'Select', value: '' },
  { title: 'Australian Capital Territory', value: 'ACT' },
  { title: 'New South Wales', value: 'NSW' },
  { title: 'Northern Territory', value: 'NT' },
  { title: 'Queensland', value: 'QLD' },
  { title: 'South Australia', value: 'SA' },
  { title: 'Tasmania', value: 'TAS' },
  { title: 'Victoria', value: 'VIC' },
  { title: 'Western Australia', value: 'WA' }
];

export function stateValid(state: string) {
  for (let i = 1; i < STATES.length; i++) {
    if (STATES[i].value === state) {
      return true;
    }
  }
  return false;
}

export const STATE_CLUB = {
  [STATES[1].value]: AUTO_CLUB[2].value, // ACT
  [STATES[2].value]: AUTO_CLUB[2].value, // NSW
  [STATES[3].value]: AUTO_CLUB[1].value, // NT
  [STATES[4].value]: AUTO_CLUB[5].value, // QLD
  [STATES[5].value]: AUTO_CLUB[3].value, // SA
  [STATES[6].value]: AUTO_CLUB[6].value, // TAS
  [STATES[7].value]: AUTO_CLUB[7].value, // VIC
  [STATES[8].value]: AUTO_CLUB[4].value // WA
};

export const COUNTRIES = [
  { title: 'Select', value: '' },
  { title: 'Afghanistan', value: 'AF' },
  { title: 'Åland Islands', value: 'AX' },
  { title: 'Albania', value: 'AL' },
  { title: 'Algeria', value: 'DZ' },
  { title: 'American Samoa', value: 'AS' },
  { title: 'Andorra', value: 'AD' },
  { title: 'Angola', value: 'AO' },
  { title: 'Anguilla', value: 'AI' },
  { title: 'Antarctica', value: 'AQ' },
  { title: 'Antigua and Barbuda', value: 'AG' },
  { title: 'Argentina', value: 'AR' },
  { title: 'Armenia', value: 'AM' },
  { title: 'Aruba', value: 'AW' },
  { title: 'Australia', value: 'AU' },
  { title: 'Austria', value: 'AT' },
  { title: 'Azerbaijan', value: 'AZ' },
  { title: 'Bahamas', value: 'BS' },
  { title: 'Bahrain', value: 'BH' },
  { title: 'Bangladesh', value: 'BD' },
  { title: 'Barbados', value: 'BB' },
  { title: 'Belarus', value: 'BY' },
  { title: 'Belgium', value: 'BE' },
  { title: 'Belize', value: 'BZ' },
  { title: 'Benin', value: 'BJ' },
  { title: 'Bermuda', value: 'BM' },
  { title: 'Bhutan', value: 'BT' },
  { title: 'Bolivia', value: 'BO' },
  { title: 'Bosnia and Herzegovina', value: 'BA' },
  { title: 'Botswana', value: 'BW' },
  { title: 'Bouvet Island', value: 'BV' },
  { title: 'Brazil', value: 'BR' },
  { title: 'British Indian Ocean Territory', value: 'IO' },
  { title: 'Brunei Darussalam', value: 'BN' },
  { title: 'Bulgaria', value: 'BG' },
  { title: 'Burkina Faso', value: 'BF' },
  { title: 'Burundi', value: 'BI' },
  { title: 'Cambodia', value: 'KH' },
  { title: 'Cameroon', value: 'CM' },
  { title: 'Canada', value: 'CA' },
  { title: 'Cape Verde', value: 'CV' },
  { title: 'Caribbean Netherlands', value: 'BQ' },
  { title: 'Cayman Islands', value: 'KY' },
  { title: 'Central African Republic', value: 'CF' },
  { title: 'Chad', value: 'TD' },
  { title: 'Chile', value: 'CL' },
  { title: 'China', value: 'CN' },
  { title: 'Christmas Island', value: 'CX' },
  { title: 'Cocos (Keeling) Islands', value: 'CC' },
  { title: 'Colombia', value: 'CO' },
  { title: 'Comoros', value: 'KM' },
  { title: 'Congo', value: 'CG' },
  { title: 'Congo, The Democratic Republic of the', value: 'CD' },
  { title: 'Cook Islands', value: 'CK' },
  { title: 'Costa Rica', value: 'CR' },
  { title: 'Cote D\'Ivoire', value: 'CI' },
  { title: 'Croatia', value: 'HR' },
  { title: 'Cuba', value: 'CU' },
  { title: 'Curaçao', value: 'CW' },
  { title: 'Cyprus', value: 'CY' },
  { title: 'Czech Republic', value: 'CZ' },
  { title: 'Denmark', value: 'DK' },
  { title: 'Djibouti', value: 'DJ' },
  { title: 'Dominica', value: 'DM' },
  { title: 'Dominican Republic', value: 'DO' },
  { title: 'Ecuador', value: 'EC' },
  { title: 'Egypt', value: 'EG' },
  { title: 'El Salvador', value: 'SV' },
  { title: 'Equatorial Guinea', value: 'GQ' },
  { title: 'Eritrea', value: 'ER' },
  { title: 'Estonia', value: 'EE' },
  { title: 'Ethiopia', value: 'ET' },
  { title: 'Falkland Islands (Malvinas)', value: 'FK' },
  { title: 'Faroe Islands', value: 'FO' },
  { title: 'Fiji', value: 'FJ' },
  { title: 'Finland', value: 'FI' },
  { title: 'France', value: 'FR' },
  { title: 'French Guiana', value: 'GF' },
  { title: 'French Polynesia', value: 'PF' },
  { title: 'French Southern Territories', value: 'TF' },
  { title: 'Gabon', value: 'GA' },
  { title: 'Gambia', value: 'GM' },
  { title: 'Georgia', value: 'GE' },
  { title: 'Germany', value: 'DE' },
  { title: 'Ghana', value: 'GH' },
  { title: 'Gibraltar', value: 'GI' },
  { title: 'Greece', value: 'GR' },
  { title: 'Greenland', value: 'GL' },
  { title: 'Grenada', value: 'GD' },
  { title: 'Guadeloupe', value: 'GP' },
  { title: 'Guam', value: 'GU' },
  { title: 'Guatemala', value: 'GT' },
  { title: 'Guernsey', value: 'GG' },
  { title: 'Guinea', value: 'GN' },
  { title: 'Guinea-Bissau', value: 'GW' },
  { title: 'Guyana', value: 'GY' },
  { title: 'Haiti', value: 'HT' },
  { title: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { title: 'Holy See (Vatican City State)', value: 'VA' },
  { title: 'Honduras', value: 'HN' },
  { title: 'Hong Kong S.A.R., China', value: 'HK' },
  { title: 'Hungary', value: 'HU' },
  { title: 'Iceland', value: 'IS' },
  { title: 'India', value: 'IN' },
  { title: 'Indonesia', value: 'ID' },
  { title: 'Iran, Islamic Republic Of', value: 'IR' },
  { title: 'Iraq', value: 'IQ' },
  { title: 'Ireland', value: 'IE' },
  { title: 'Isle of Man', value: 'IM' },
  { title: 'Israel', value: 'IL' },
  { title: 'Italy', value: 'IT' },
  { title: 'Jamaica', value: 'JM' },
  { title: 'Japan', value: 'JP' },
  { title: 'Jersey', value: 'JE' },
  { title: 'Jordan', value: 'JO' },
  { title: 'Kazakhstan', value: 'KZ' },
  { title: 'Kenya', value: 'KE' },
  { title: 'Kiribati', value: 'KI' },
  { title: 'Korea, Democratic People\'S Republic of', value: 'KP' },
  { title: 'Korea, Republic of', value: 'KR' },
  { title: 'Kosovo', value: 'XK' },
  { title: 'Kuwait', value: 'KW' },
  { title: 'Kyrgyzstan', value: 'KG' },
  { title: 'Lao People\'S Democratic Republic', value: 'LA' },
  { title: 'Latvia', value: 'LV' },
  { title: 'Lebanon', value: 'LB' },
  { title: 'Lesotho', value: 'LS' },
  { title: 'Liberia', value: 'LR' },
  { title: 'Libyan Arab Jamahiriya', value: 'LY' },
  { title: 'Liechtenstein', value: 'LI' },
  { title: 'Lithuania', value: 'LT' },
  { title: 'Luxembourg', value: 'LU' },
  { title: 'Macao', value: 'MO' },
  { title: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { title: 'Madagascar', value: 'MG' },
  { title: 'Malawi', value: 'MW' },
  { title: 'Malaysia', value: 'MY' },
  { title: 'Maldives', value: 'MV' },
  { title: 'Mali', value: 'ML' },
  { title: 'Malta', value: 'MT' },
  { title: 'Marshall Islands', value: 'MH' },
  { title: 'Martinique', value: 'MQ' },
  { title: 'Mauritania', value: 'MR' },
  { title: 'Mauritius', value: 'MU' },
  { title: 'Mayotte', value: 'YT' },
  { title: 'Mexico', value: 'MX' },
  { title: 'Micronesia, Federated States of', value: 'FM' },
  { title: 'Moldova, Republic of', value: 'MD' },
  { title: 'Monaco', value: 'MC' },
  { title: 'Mongolia', value: 'MN' },
  { title: 'Montenegro', value: 'ME' },
  { title: 'Montserrat', value: 'MS' },
  { title: 'Morocco', value: 'MA' },
  { title: 'Mozambique', value: 'MZ' },
  { title: 'Myanmar', value: 'MM' },
  { title: 'Namibia', value: 'NA' },
  { title: 'Nauru', value: 'NR' },
  { title: 'Nepal', value: 'NP' },
  { title: 'Netherlands', value: 'NL' },
  { title: 'New Caledonia', value: 'NC' },
  { title: 'New Zealand', value: 'NZ' },
  { title: 'Nicaragua', value: 'NI' },
  { title: 'Niger', value: 'NE' },
  { title: 'Nigeria', value: 'NG' },
  { title: 'Niue', value: 'NU' },
  { title: 'Norfolk Island', value: 'NF' },
  { title: 'Northern Mariana Islands', value: 'MP' },
  { title: 'Norway', value: 'NO' },
  { title: 'Oman', value: 'OM' },
  { title: 'Pakistan', value: 'PK' },
  { title: 'Palau', value: 'PW' },
  { title: 'Palestinian Territory, Occupied', value: 'PS' },
  { title: 'Panama', value: 'PA' },
  { title: 'Papua New Guinea', value: 'PG' },
  { title: 'Paraguay', value: 'PY' },
  { title: 'Peru', value: 'PE' },
  { title: 'Philippines', value: 'PH' },
  { title: 'Pitcairn', value: 'PN' },
  { title: 'Poland', value: 'PL' },
  { title: 'Portugal', value: 'PT' },
  { title: 'Puerto Rico', value: 'PR' },
  { title: 'Qatar', value: 'QA' },
  { title: 'Reunion', value: 'RE' },
  { title: 'Romania', value: 'RO' },
  { title: 'Russian Federation', value: 'RU' },
  { title: 'Rwanda', value: 'RW' },
  { title: 'Saint Helena', value: 'SH' },
  { title: 'Saint Barthélemy', value: 'BL' },
  { title: 'Saint Kitts and Nevis', value: 'KN' },
  { title: 'Saint Lucia', value: 'LC' },
  { title: 'Saint Martin (French part)', value: 'MF' },
  { title: 'Saint Pierre and Miquelon', value: 'PM' },
  { title: 'Saint Vincent and the Grenadines', value: 'VC' },
  { title: 'Samoa', value: 'WS' },
  { title: 'San Marino', value: 'SM' },
  { title: 'Sao Tome and Principe', value: 'ST' },
  { title: 'Saudi Arabia', value: 'SA' },
  { title: 'Senegal', value: 'SN' },
  { title: 'Serbia', value: 'RS' },
  { title: 'Seychelles', value: 'SC' },
  { title: 'Sierra Leone', value: 'SL' },
  { title: 'Singapore', value: 'SG' },
  { title: 'Sint Maarten', value: 'SX' },
  { title: 'Slovakia', value: 'SK' },
  { title: 'Slovenia', value: 'SI' },
  { title: 'Solomon Islands', value: 'SB' },
  { title: 'Somalia', value: 'SO' },
  { title: 'South Africa', value: 'ZA' },
  { title: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { title: 'South Sudan', value: 'SS' },
  { title: 'Spain', value: 'ES' },
  { title: 'Sri Lanka', value: 'LK' },
  { title: 'Sudan', value: 'SD' },
  { title: 'Suriname', value: 'SR' },
  { title: 'Svalbard and Jan Mayen', value: 'SJ' },
  { title: 'Swaziland', value: 'SZ' },
  { title: 'Sweden', value: 'SE' },
  { title: 'Switzerland', value: 'CH' },
  { title: 'Syrian Arab Republic', value: 'SY' },
  { title: 'Taiwan', value: 'TW' },
  { title: 'Tajikistan', value: 'TJ' },
  { title: 'Tanzania, United Republic of', value: 'TZ' },
  { title: 'Thailand', value: 'TH' },
  { title: 'Timor-Leste', value: 'TL' },
  { title: 'Togo', value: 'TG' },
  { title: 'Tokelau', value: 'TK' },
  { title: 'Tonga', value: 'TO' },
  { title: 'Trinidad and Tobago', value: 'TT' },
  { title: 'Tunisia', value: 'TN' },
  { title: 'Turkey', value: 'TR' },
  { title: 'Turkmenistan', value: 'TM' },
  { title: 'Turks and Caicos Islands', value: 'TC' },
  { title: 'Tuvalu', value: 'TV' },
  { title: 'Uganda', value: 'UG' },
  { title: 'Ukraine', value: 'UA' },
  { title: 'United Arab Emirates', value: 'AE' },
  { title: 'United Kingdom', value: 'GB' },
  { title: 'United States', value: 'US' },
  { title: 'United States Minor Outlying Islands', value: 'UM' },
  { title: 'Uruguay', value: 'UY' },
  { title: 'Uzbekistan', value: 'UZ' },
  { title: 'Vanuatu', value: 'VU' },
  { title: 'Venezuela', value: 'VE' },
  { title: 'Vietnam', value: 'VN' },
  { title: 'Virgin Islands, British', value: 'VG' },
  { title: 'Virgin Islands, U.S.', value: 'VI' },
  { title: 'Wallis and Futuna', value: 'WF' },
  { title: 'Western Sahara', value: 'EH' },
  { title: 'Yemen', value: 'YE' },
  { title: 'Zambia', value: 'ZM' },
  { title: 'Zimbabwe', value: 'ZW' }
];

export const NUMBER_AFTER = [
  'AL',
  'AM',
  'AR',
  'AT',
  'BY',
  'BD',
  'BE',
  'BR',
  'BA',
  'BG',
  'CL',
  'CO',
  'CZ',
  'DK',
  'EE',
  'FI',
  'GE',
  'DE',
  'GI',
  'GD',
  'GR',
  'VA',
  'HU',
  'IS',
  'IE',
  'IT',
  'KZ',
  'KG',
  'LV',
  'LI',
  'LT',
  'LU',
  'MK',
  'MT',
  'MD',
  'MC',
  'ME',
  'MS',
  'MA',
  'NL',
  'AN',
  'NO',
  'PE',
  'RO',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'UZ'
];

export const MAYBE_POSTCODE = ['CL', 'IS'];

export const DELIVERY_MSG = {
  International:
    'For International addresses, postage is subject to <a href="https://auspost.com.au/sending/delivery-speeds-and-coverage/international-delivery-times" target="_blank" nofollow noreferrer>Australia Post international delivery timeframes</a>.',
  Australian:
    'For Australian addresses, postage is subject to <a href="https://auspost.com.au/service-updates/domestic-delivery-times" target="_blank" nofollow noreferrer>Australia Post domestic delivery timeframes</a>.'
};
export const FIELD_LENGTH = 120;

export const FIELDS = {
  addresses: {
    addressFieldsToggle: false, // toggle between search and manual input
    addressDeliveryFieldsToggle: false, // toggle between search and manual input
    addressUseSameToggle: false, //toggle copy address to sue same for delivery
    licenceAddress: {
      error: false,
      placeholder: 'Search address',
      required: true,
      valid: false,
      value: ''
    },
    licenceAddressStreet: {
      error: false,
      required: true,
      valid: false,
      value: ''
    },
    licenceAddressLocality: {
      error: false,
      required: true,
      valid: false,
      value: ''
    },
    licenceAddressState: {
      error: false,
      required: true,
      states: STATES,
      valid: false,
      value: ''
    },
    licenceAddressCountry: {
      error: false,
      display: '',
      required: true,
      valid: true,
      value: 'AU'
    },
    licenceAddressPostcode: {
      error: false,
      label: 'Postcode',
      regex: '\\d{4}',
      required: true,
      valid: false,
      value: ''
    },
    licenceDelivery: 'Australian',
    licenceDeliveryAddress: {
      error: false,
      format: '%O%n%N%n%A%n%C %S %Z',
      placeholder: 'Search address',
      required: true,
      valid: false,
      value: ''
    },
    licenceDeliveryAddressFullName: {
      error: false,
      placeholder: '',
      required: false,
      valid: false,
      value: ''
    },
    licenceDeliveryAddressStreet: {
      error: false,
      required: true,
      valid: false,
      value: ''
    },
    licenceDeliveryAddressLocality: {
      error: false,
      label: 'Town/Suburb',
      required: true,
      valid: false,
      value: ''
    },
    licenceDeliveryAddressState: {
      display: '',
      error: false,
      label: 'State',
      required: true,
      states: STATES,
      valid: false,
      value: ''
    },
    licenceDeliveryAddressCountry: {
      display: 'Australia',
      error: false,
      required: true,
      valid: false,
      value: 'AU'
    },
    licenceDeliveryAddressPostcode: {
      error: false,
      label: 'Postcode',
      regex: '\\d{4}',
      required: true,
      valid: false,
      value: ''
    }
  },
  contacts: { ...initContacts },
  club: {
    autoClub: {
      error: false,
      required: true,
      valid: null,
      value: ''
    },
    membershipNumber: {
      error: false,
      required: false,
      valid: null,
      value: ''
    }
  },
  dateOfBirth: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  deliveryMethod: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  deliveryPickUpStore: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  email: {
    error: false,
    label: 'Email',
    placeholder: '',
    required: true,
    valid: false,
    value: ''
  },
  images: { ...initImages },
  licenceClass: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  licenceExpiry: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  licenceNumber: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  licenceStateOfIssue: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  names: {
    firstName: {
      error: false,
      required: true,
      valid: null,
      value: ''
    },
    fullName: {
      error: false,
      required: true,
      valid: null,
      value: ''
    },
    lastName: {
      error: false,
      required: true,
      valid: null,
      value: ''
    },
    middleName: {
      error: false,
      required: false,
      valid: null,
      value: ''
    },
    licenceDeliveryAddressFullName: {
      error: false,
      overridden: false,
      required: true,
      valid: null,
      value: ''
    },
    title: {
      error: false,
      required: false,
      valid: null,
      value: ''
    }
  },
  placeOfBirth: {
    error: false,
    required: true,
    valid: true,
    value: 'AU'
  },
  postage: {
    error: false,
    required: false,
    valid: true,
    value: 'express_au'
  },
  travelDate: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  wearGlasses: {
    error: false,
    required: true,
    valid: false,
    value: ''
  }
};

export const I18N = 'https://chromium-i18n.appspot.com/ssl-address/data/';

// Relative path to images depends on if we are running as pure react, or embedded in Drupal.
export const IMAGE_PATH_PREFIX = '/images/';

export const OPTIONS = {
  method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const PORTRAIT_IMAGE_UPLOAD = {
  width: {
    min: {
      errorMessage: 'Your image is too small, it must be at least 477px wide',
      size: 477
    },
    max: {
      errorMessage: 'Your image is too large, it must be less than 4500px wide',
      size: 4500
    }
  },
  height: {
    min: {
      errorMessage: 'Your image is too small, it must be at least 636px high',
      size: 636
    },
    max: {
      errorMessage: 'Your image is too large, it must be at less than 6000px high',
      size: 6000
    }
  }
};

export const LANDSCAPE_IMAGE_UPLOAD = {
  width: {
    min: {
      errorMessage: 'Your image is too small, it must be at least 640px wide',
      size: 640
    },
    max: {
      errorMessage: 'Your image is too large, it must be less than 4500px wide',
      size: 4500
    }
  },
  height: {
    min: {
      errorMessage: 'Your image is too small, it must be at least 400px high',
      size: 400
    },
    max: {
      errorMessage: 'Your image is too large, it must be at less than 6000px high',
      size: 6000
    }
  }
};

export const LICENCE_CLASS = [
  { title: 'Select', value: '' },
  { title: 'C - Car', value: 'C' },
  { title: 'C & R – Car and Motorcycle', value: 'C+R' },
  { title: 'LR - Light Rigid', value: 'LR' },
  { title: 'MR - Medium Rigid', value: 'MR' },
  { title: 'HR - Heavy Rigid', value: 'HR' },
  { title: 'HC - Heavy Combination', value: 'HC' },
  { title: 'MC - Multi Combination', value: 'MC' },
  {
    title:
      'R-DATE - Motorcycle or trike with an engine capacity up to 660ml and a power-to-weight ratio not exceeding 150kw/tonne',
    value: 'R-DATE'
  },
  { title: 'R - Motorcycle', value: 'R' }
];

export const MONTHS = [
  { title: 'January', value: '01' },
  { title: 'February', value: '02' },
  { title: 'March', value: '03' },
  { title: 'April', value: '04' },
  { title: 'May', value: '05' },
  { title: 'June', value: '06' },
  { title: 'July', value: '07' },
  { title: 'August', value: '08' },
  { title: 'September', value: '09' },
  { title: 'October', value: '10' },
  { title: 'November', value: '11' },
  { title: 'December', value: '12' }
];

export const PHOTOS = [
  {
    correct: true,
    source: 'photo-guidelines/correct1.png',
    title: 'Acceptable'
  },
  {
    correct: true,
    source: 'photo-guidelines/correct2.png',
    title: 'Acceptable'
  },
  {
    correct: false,
    source: 'photo-guidelines/incorrect1.png',
    title: 'No glasses'
  },
  {
    correct: false,
    source: 'photo-guidelines/incorrect2.png',
    title: 'Hair obscuring face'
  },
  {
    correct: false,
    source: 'photo-guidelines/incorrect3.png',
    title: 'Background not plain'
  },
  {
    correct: false,
    source: 'photo-guidelines/incorrect4.png',
    title: 'Insufficient contrast'
  }
];

export const POSTAGE = [
  {
    value: 'express_post',
    description: 'Express Post within Australia',
    cost: 11.40
  },
  {
    value: 'express_international',
    description: 'International Express Postage (limited tracking)',
    cost: 56.90
  },
  {
    value: 'pick_up',
    description: 'In-store pickup',
    cost: 0.00
  }
];

export const STARSHIPIT = {
  apiKey: 'd26284ce6a56420fbda42b5e10fd76d4',
  primaryKey: 'f9b6dd8f00a24f7ab7854c17ab5f372b',
  url: 'https://api.starshipit.com/api/rates'
};

export const TITLE = [
  { title: '--', value: '' },
  { title: 'Dr', value: 'Dr' },
  { title: 'Miss', value: 'Miss' },
  { title: 'Mr', value: 'Mr' },
  { title: 'Mrs', value: 'Mrs' },
  { title: 'Ms', value: 'Ms' },
  { title: 'Prof', value: 'Prof' }
];

export const COSTS = {
  permit: 51
};

export const DELIVERY_METHOD_POSTAGE = {
  description: '<strong>Postage</strong>: Once your application is reviewed and approved, your permit will be dispatched within <strong>3 business days</strong>. It may take longer for public holidays and regional delivery subject to Australia Post delivery times. You will receive a confirmation email with tracking details.',
  value: 'Postage',
};
export const DELIVERY_METHOD_PICK_UP_IN_STORE = {
  description: '<strong>Pick up in-store</strong> (limited stores only): Once your application is reviewed and approved, your permit will be available for pick up at the selected store within X days.',
  value: 'PickUpInStore',
}

export const MAX_FUTURE = moment().add(5, 'years');
export const MIN_FUTURE = moment().add(6, 'days');
export const ZIP = ['AS', 'FM', 'GU', 'MH', 'MP', 'PR', 'PW', 'UM', 'US', 'VI'];
export const SLACK_CHANNEL = 'https://hooks.slack.com/services/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';
